@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
/************ Typography ************/
/************ Typography End************/
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
/************ Typography ************/
/************ Typography End************/
/* Common style here */
/*=========== Typograhy ===========*/
.typo-h1 {
  font-size: 50px;
}

.typo-h2 {
  font-size: 42px;
}

.typo-h3 {
  font-size: 28px;
}

.typo-h4 {
  font-size: 24px;
}

.typo-h5 {
  font-size: 18px;
}

.typo-h6 {
  font-size: 16px;
}

.typo-p-14 {
  font-size: 14px;
}

/*=========== Typograhy ===========*/
/*=========== Button Style ===========*/
.button {
  display: inline-block;
  font-weight: 700;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  border-radius: 0.25rem;
  text-transform: capitalize;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.button-primary {
  color: #52bd94;
  background-color: #52bd941a;
}

.button-secondary {
  color: #ffffff;
  background-color: #5f56c3;
  padding: 12px;
}

.button-whitebg {
  color: #52bd94;
  background-color: #fff;
  font-size: 18px;
  padding: 12px 20px;
}

/*=========== Button Style ===========*/
/* Create a custom checkbox */
.chekmark-style {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 21px;
  cursor: pointer;
  font-size: 16px;
  color: #444444;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chekmark-style input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  border: 1px solid #52bd94;
  width: 24px;
  border-radius: 5px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
/* When the checkbox is checked, add a blue background */
.chekmark-style input:checked ~ .checkmark {
  background-color: #52bd94;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chekmark-style input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chekmark-style .checkmark:after {
  left: 7px;
  top: -1px;
  width: 7px;
  height: 16px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* pagination style */
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 20px;
}

.pagination .btn {
  border: 2px solid #52bd94;
  width: 48px;
  height: 48px;
  background-color: #52bd94;
  cursor: pointer;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 7px;
}

.pagination .btn:active,
.pagination .btn:hover {
  background-color: #52bd94;
}

.pagination .icon {
  width: 24px;
  height: 24px;
  stroke: #ffffff;
}

.pagination .btn:hover .icon {
  stroke: #ffffff;
}

.pagination .page--link:link,
.pagination .page--link:visited {
  font-size: 14px;
  font-weight: 700;
  color: #52bd94;
  text-decoration: none;
  border: 1px solid #52bd94;
  border-radius: 50%;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 7px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 50px;
  width: 50px;
}

.pagination .page--link:active,
.pagination .page--link:hover,
.pagination .page--link.page--link--current {
  background-color: #52bd94;
  color: #ffffff;
  border-radius: 50%;
}

.pagination .link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pagination span {
  color: #adb5bd;
}

/* pagination style */
/* Form style */
.form-inner form .field {
  margin-top: 40px;
}

.form-inner form .field input {
  height: 50px;
  width: 90%;
  margin-top: 8px;
  outline: none;
  font-size: 17px;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid #ebebe6;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.form-inner form .field input:focus {
  border-color: #52bd94;
}

textarea#w3review {
  border: 1px solid #ebebe6;
  color: #000;
  height: 162px;
  width: 90%;
  margin-top: 8px;
  outline: none;
  font-size: 17px;
  padding: 15px;
  border-radius: 5px;
}

#upload_link {
  color: #483ea8;
}

#upload {
  display: none;
}

/* Form style */
/* Common style here */
/*===================== Home Page Start ===================== */
body {
  font-family: "Inter", sans-serif;
  outline: none;
  background: #f1faf6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1080px;
  width: 90%;
  margin: auto;
}

.navbar {
  top: 0;
  -webkit-box-shadow: 0px 0px 20px 0px #aaaaaa4a;
  box-shadow: 0px 0px 20px 0px #aaaaaa4a;
  position: fixed;
  width: 100%;
  background: #ffffff;
  color: #444444;
  z-index: 100;
}

.navbar .navbar-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 90px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .navbar-container .logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 75%;
}

.navbar .navbar-container .logo .typo-h4 {
  margin-left: 22px;
  color: #444444;
  text-transform: uppercase;
  font-size: 20px;
}

/* banner section */
.banner {
  background-image: url("../images/banner1.png");
  background-attachment: scroll;
  background-size: cover;
  background-position: center;
  height: 689px;
  width: 100%;
  position: relative;
}

.banner .hero-text {
  position: absolute;
  /* left: 20%; */
  /* margin: auto; */
  top: 21%;
  padding: 0px 9rem;
  text-align: center;
}

.banner .hero-text .typo-h2 {
  color: #444444;
}

.banner .hero-text .typo-h2 .different {
  color: #52bd94;
}

.banner .hero-text .typo-h6 {
  font-weight: 400;
  color: #505051;
  margin-top: 32px;
  margin-bottom: 32px;
}

.banner .search-sec .typo-h4 {
  margin-bottom: 21px;
  margin-top: 41px;
  color: #444444;
}

.banner .search-sec .downsec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 62px;
}

.banner .search-sec .downsec .search {
  position: relative;
  display: flex;
}

.search-keyword,
.search-location {
  position: relative;
}

.banner .search-sec .downsec .search img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 19px;
  z-index: 2;
  left: 5%;
}

.banner .search-sec .downsec .search .loc {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 19px;
  z-index: 2;
  right: 5%;
}

.banner .search-sec .downsec .search .input-elevated {
  font-size: 14px;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #ffffff;
  width: 230px;
  position: relative;
  height: 17px;
  padding: 18px 15px 15px 36px;
}

.banner .search-sec .downsec .search .search-location .input-elevated {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.banner .search-sec .downsec .search .sideline {
  position: absolute;
  top: -4px;
  left: 49%;
  width: 1px;
  height: 31px;
  background: #c9c9c982;
}

.banner .search-sec .downsec .input-elevated::-webkit-input-placeholder {
  color: #c9c9c9;
}

.banner .search-sec .downsec .input-elevated:-ms-input-placeholder {
  color: #c9c9c9;
}

.banner .search-sec .downsec .input-elevated::-ms-input-placeholder {
  color: #c9c9c9;
}

.banner .search-sec .downsec .input-elevated::placeholder {
  color: #c9c9c9;
}

.banner .search-sec .downsec .input-elevated:focus {
  outline: none;
}

.banner .search-sec .dropdown {
  display: inline-block;
  position: relative;
}

.banner .search-sec .dropdown img {
  width: 16px;
  height: 17px;
  position: absolute;
  top: 19px;
  z-index: 2;
  left: 7%;
}

.banner .search-sec .dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.banner .search-sec .dropdown .dropdown:hover .dropdown-content {
  display: block;
}

.banner .search-sec .dropdown .dd-button {
  display: inline-block;
  padding: 17px 77px 18px 42px;
  height: 18px;
  width: 72px;
  border: none;
  font-size: 14px;
  background: #ffffff;
  color: #c9c9c9;
  cursor: pointer;
  white-space: nowrap;
}

.banner .search-sec .dropdown .dd-button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2px;
  height: 2px;
  border-left: 5px solid #00000000;
  border-right: 5px solid transparent;
  border-top: 5px solid #c9c9c9;
}

.banner .search-sec .dropdown .dd-input {
  display: none;
}

.banner .search-sec .dropdown .dd-menu {
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  list-style-type: none;
}

.banner .search-sec .dropdown .dd-input + .dd-menu {
  display: none;
}

.banner .search-sec .dropdown .dd-input:checked + .dd-menu {
  display: block;
}

.banner .search-sec .dropdown .dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.banner .search-sec .dropdown .dd-menu li:hover {
  background-color: #f6f6f6;
}

.banner .search-sec .dropdown .dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.banner .search-sec .dropdown .dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

.banner .mobilebtn {
  display: none;
}

/* banner section */
/* home filter section */
.current-openings {
  margin-bottom: 5rem;
  margin-top: 3rem;
}

.current-openings .filterIcon {
  display: none;
}

.current-openings .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.current-openings .row .left-sec {
  width: 20%;
  margin-right: 2rem;
  margin-top: 62px;
}

.current-openings .row .left-sec .filterheading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #d5eae5;
  cursor: pointer;
}

.current-openings .row .left-sec .filterheading .typo-h5 {
  color: #444444;
}

.current-openings .row .left-sec .filterheading a {
  text-decoration: none;
}

.current-openings .row .left-sec .filterheading .typo-p-14 {
  font-weight: 700;
  color: #5f56c3;
}

.current-openings .row .left-sec .filterheading .typo-h5 {
  margin: 0;
}

.current-openings .row .left-sec .filterbody .typo-h6 {
  margin-bottom: 24px;
  color: #444444;
  margin-top: 32px;
}

.current-openings .row .togalmobilenav {
  display: none;
}

.current-openings .row .mobileleft-sec {
  width: 84%;
  padding: 21px;
}

.current-openings .row .mobileleft-sec .closeic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: #ddd;
  font-size: 18px;
}

.current-openings .row .mobileleft-sec .filterheadin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc80;
}

.current-openings .row .mobileleft-sec .filterheadin a {
  text-decoration: none;
}

.current-openings .row .mobileleft-sec .filterheadin .filter {
  color: #444444;
  font-size: 14px;
  font-weight: 700;
}

.current-openings .row .mobileleft-sec .filterheadin .typo-p-14 {
  font-weight: 600;
  color: #5f56c3;
}

.current-openings .row .mobileleft-sec .filterheadin .typo-h5 {
  margin: 0;
}

.current-openings .row .mobileleft-sec .filterbody .typo-h6 {
  margin-bottom: 22px;
  color: #000;
  margin-top: 30px;
}

.current-openings .row .togalmobilenav {
  margin-top: 32px;
  width: 19%;
}

.current-openings .row .togalmobilenav .icon {
  font-size: 24px;
  color: #52bd94;
}

.current-openings .row .right-sec {
  width: 100%;
}

.current-openings .row .right-sec .typo-p-14 {
  margin-bottom: 25px;
  border-bottom: 1px solid #d5eae5;
  padding-bottom: 16px;
}

.current-openings .row .right-sec .typo-h4 {
  margin-bottom: 0;
  color: #444444;
}

.current-openings .row .right-sec .card {
  background: #ffffff;
  padding: 40px;
  border-radius: 12px;
  margin-top: 20px;
}

.current-openings .row .right-sec .card .cardtop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.current-openings .row .right-sec .card .cardtop .typo-h3 {
  margin: 0;
  color: #52bd94;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.current-openings .row .right-sec .card .cardtop .typo-h3 .typo-h6 {
  color: #505051;
  margin-left: 12px;
}

.current-openings .row .right-sec .card .cardbages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
}

.current-openings .row .right-sec .card .cardbages .typo-h6 {
  background: #e6f6f0;
  color: #52bd94;
  padding: 7px;
  border-radius: 5px;
  margin-right: 16px;
  margin-top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}

.current-openings .row .right-sec .card .cardbages .typo-h6 img {
  margin-right: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.current-openings .row .right-sec .card .cardinfo .typo-h6 {
  font-weight: 400;
  margin: 27px 1px 0;
  color: #444444;
  line-height: 28px;
}

.current-openings .row .right-sec .card .applynow-mobile {
  display: none;
}

.current-openings .row .right-sec .bottom-card {
  background-image: url("../images/upgradcard.png");
  padding: 40px;
  border-radius: 12px;
  margin-top: 49px;
}

.current-openings .row .right-sec .bottom-card .bottomcardtop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.current-openings .row .right-sec .bottom-card .bottomcardtop .bottomcardinner {
  width: 63%;
}

.current-openings .row .right-sec .bottom-card .bottomcardtop .bottomcardinner .typo-h4 {
  margin: 0;
  color: #ffffff;
}

.current-openings .row .right-sec .bottom-card .bottomcardtop .bottomcardinner .typo-p-14 {
  border-bottom: none;
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 600;
}

/* mobile filter css */
.filtermobile {
  overflow: hidden;
  position: absolute;
  background: #ffffff;
  left: 0;
  top: 688px;
  width: 250px;
  border-radius: 0px 12px 12px 0px;
  -webkit-box-shadow: 1px 5px 22px 3px #7270705e;
  box-shadow: 1px 5px 22px 3px #7270705e;
}

.filtermobile #myLinks {
  display: none;
}

.filtermobile a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.filtermobile a.icon {
  display: block;
  color: #0000002b;
  position: absolute;
  right: -71px;
  top: -58px;
}

.filtermobile a:hover {
  background-color: #ddd;
  color: black;
}

.active {
  color: white;
}

/* mobile filter css */
/* home filter section */
/* job description section */
.jobdes-banner {
  background-image: url("../images/banner2.png");
  background-attachment: scroll;
  background-size: cover;
  background-position: center;
  height: 317px;
  width: 100%;
  position: relative;
}

.jobdes-banner .container .jobhero-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10rem 65px;
}

.jobdes-banner .container .jobhero-text .jobhero-texttop .typo-h3 {
  margin: 0;
  color: #444444;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.jobdes-banner .container .jobhero-text .jobhero-texttop .typo-h3 .typo-h6 {
  margin-left: 12px;
}

.jobdes-banner .container .jobhero-text .jobhero-texttop .jobhero-textbages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
}

.jobdes-banner .container .jobhero-text .jobhero-texttop .jobhero-textbages .typo-h6 {
  background: #ffffff;
  color: #52bd94;
  padding: 7px;
  border-radius: 5px;
  margin-right: 16px;
  margin-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}

.jobdes-banner .container .jobhero-text .jobhero-texttop .jobhero-textbages .typo-h6 img {
  margin-right: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 14px;
  height: 16px;
}

.jobdescription {
  background: #ffffff;
  padding: 47px 42px;
  margin-top: 52px;
  border-radius: 12px;
}

.jobdescription .paragraph {
  line-height: 21px;
}

.jobdescription .paragraph ul {
  padding-left: 18px;
  margin: 0px;
}

.jobdescription .paragraph .typo-h4 {
  color: #444444;
  margin-top: 0;
  margin-bottom: 43px;
}

.jobdescription .paragraph .boldtext {
  font-weight: 600;
  color: #444444;
}

.jobdescription .paragraph .typo-p-14 {
  color: #444444;
  line-height: 28px;
  font-size: 16px;
}

.jobdescription .breifdec .typo-h4 {
  color: #444444;
  margin-top: 0;
  margin-bottom: 20px;
}

.jobdescription .breifdec .benefits-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.jobdescription .breifdec .benefits-part .ben1 {
  width: 35%;
}

.jobdescription .breifdec .benefits-part .ben1 .typo-h6 {
  color: #444444;
  margin-top: 19px;
}

.jobdescription .breifdec .benefits-part .ben1 img {
  margin-right: 10px;
}

.jobdescription .breifdec .benefits-part .ben2 .typo-h6 {
  color: #444444;
  margin-top: 19px;
}

.jobdescription .breifdec .benefits-part .ben2 img {
  margin-right: 10px;
}

.jobdec-button {
  margin-top: 36px;
  margin-bottom: 65px;
}

/* job description section */
/* applyform section */
.form-container {
  background: #ffffff;
  padding: 47px 42px;
  border-radius: 12px;
  margin-top: 52px;
  margin-bottom: 65px;
}

.form-container .form-inner {
  margin-bottom: 2rem;
}

.form-container .form-inner .formfield {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-container .form-inner .formfield .leftfield {
  width: 50%;
}

.form-container .form-inner .formfield .rightfield {
  width: 50%;
}

.form-container .form-inner .formfield .rightfield .uploadfilecss {
  background: #5f56c30d;
  padding: 23px;
  text-align: center;
  width: 85%;
  margin-top: 8px;
  border-radius: 7px;
  border: 1px dashed #ccc;
}

.form-container .form-inner .formfield .rightfield .uploadfilecss .typo-h6 {
  font-weight: 600;
  color: #444444;
}

.form-container .form-inner .formfield .rightfield .uploadfilecss .typo-p-14 {
  font-size: 12px;
  color: #676767;
}

.form-container .form-inner .formfield .typo-h6 {
  color: #444444;
  font-weight: 700;
}

.form-container .form-inner .frombtn {
  margin-top: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.form-container .heading .typo-h4 {
  color: #444444;
  margin-bottom: 30px;
  margin-top: 0;
}

.form-container .heading .typo-h6 {
  color: #444444;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 12px;
  border-bottom: 1px solid #6c63d126;
  padding-bottom: 48px;
}

/* applyform section */
/* thankyoupage section */
.thankyoubox {
  background: #ffffff;
  padding: 47px 42px;
  border-radius: 12px;
  margin-top: 9rem;
  margin-bottom: 65px;
  text-align: center;
}

.thankyoubox .typo-h2 {
  color: #444444;
  margin-bottom: 21px;
  font-weight: 400;
}

.thankyoubox .line {
  margin: 0;
  position: relative;
  color: #444444;
}

.thankyoubox .line:before {
  content: "";
  display: block;
  width: 23px;
  height: 2px;
  background: #00000024;
  left: 46%;
  top: 50%;
  position: absolute;
}

.thankyoubox .line:after {
  content: "";
  display: block;
  width: 23px;
  height: 2px;
  background: #00000024;
  right: 46%;
  top: 50%;
  position: absolute;
}

.thankyoubox .typo-h6 {
  color: #444444;
  font-weight: 400;
  margin-top: 24px;
  margin-top: 40px;
  line-height: 28px;
}

/* thankyoupage section */
/* Footer section */
.footersec {
  background: #4b4592;
  padding: 24px 0 24px 0px;
}

.footersec .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footersec .container .footerlogo-left img {
  width: 137px;
}

.footersec .container .middlesec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
}

.footersec .container .middlesec .typo-p-14 {
  margin-right: 5px;
  margin-left: 5px;
  font-weight: 700;
}

.footersec .container .footerlogo-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footersec .container .footerlogo-right .typo-p-14 {
  color: #ffffff;
  font-weight: 700;
}

.footersec .container .footerlogo-right img {
  width: 100px;
}

.closefilter {
  display: none;
}

/* Footer section */
/* Responsive Media Style */
@media (min-device-width: 1025px) and (max-device-width: 1440px) {
  .banner .hero-text {
    top: 17%;
    padding: 0px 9rem;
  }
}

@media (min-device-width: 901px) and (max-device-width: 1024px) {
  .banner .hero-text {
    top: 17%;
    padding: 0px 1rem;
    left: 11%;
  }
  .filterIcon {
    position: absolute;
    display: block !important;
    color: #52bd94;
    font-size: 24px;
  }
  .current-openings .row .left-sec {
    display: none;
  }
  .current-openings .row .left-secFilter {
    display: block;
  }
  .current-openings .row .left-sec {
    width: 42%;
    margin-right: 0;
    margin-top: 0;
    background: #fff !important;
    position: absolute;
    top: 145%;
    left: 0;
    -webkit-box-shadow: 3px 3px 15px 4px #ccc6;
    box-shadow: 3px 3px 15px 4px #ccc6;
    border-radius: 5px 5px 5px 5px;
    padding: 34px;
  }
}

@media (min-device-width: 769px) and (max-device-width: 900px) {
  .filterIcon {
    position: absolute;
    display: block !important;
    color: #52bd94;
    font-size: 24px;
  }
  .current-openings .row .left-sec {
    width: 42%;
    margin-right: 0;
    margin-top: 0;
    background: #fff !important;
    position: fixed;
    top: 145%;
    left: 0;
    -webkit-box-shadow: 3px 3px 15px 4px #ccc6;
    box-shadow: 3px 3px 15px 4px #ccc6;
    border-radius: 5px 5px 5px 5px;
    padding: 34px;
  }
  .banner .hero-text {
    top: 17%;
    padding: 0px 1rem;
  }
  .current-openings .row .left-sec {
    display: none;
  }
  .current-openings .row .left-secFilter {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .closefilter {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: bold;
    padding: 5px 8px;
  }

  .filterIcon {
    position: absolute;
    display: block !important;
    color: #52bd94;
    font-size: 24px;
  }
  .current-openings .row .left-sec {
    width: 230px;
    height: 100%;
    margin-right: 0;
    margin-top: 0;
    background: #fff !important;
    position: fixed;
    top: 90px;
    left: 0;

    -webkit-box-shadow: 3px 3px 15px 4px #ccc6;
    box-shadow: 3px 3px 15px 4px #ccc6;
    border-radius: 5px 5px 5px 5px;
    padding: 34px;
    z-index: 999;
  }
  .navbar .navbar-container .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%;
  }
  .navbar .navbar-container .logo .typo-h4 {
    font-size: 16px !important;
    margin: 5px 0 !important;
  }
  .logo a {
    width: 58% !important;
  }
  .banner img {
    height: 59vh;
    margin-top: 3rem;
  }
  .banner .hero-text {
    top: 17% !important;
    left: 0 !important;
    padding: 0 56px;
  }
  .banner .hero-text .typo-h2 {
    font-size: 32px;
  }
  .banner .hero-text .typo-h6 {
    font-size: 14px;
    /* margin-top: 10px; */
    /* margin-bottom: 20px; */
  }
  .banner .hero-text .search-sec .typo-h4 {
    font-size: 24px;
    margin-top: 20px;
  }
  .banner .search-sec .downsec .search .input-elevated {
    border-radius: 5px !important;
    height: 17px;
    padding: 18px 10px 18px 42px;
    margin-bottom: 10px;
  }

  .banner .search-sec .dropdown .dd-button {
    padding: 17px 77px 18px 36px !important;
    height: 18px;
    width: 30px;
    border-radius: 0px 5px 0px 5px;
  }
  .banner .search-sec .downsec .search .sideline {
    display: none;
  }
  .banner .search-sec .downsec .search .loc {
    top: -31px;
  }
  .banner .search-sec .downsec .search img {
    top: -29px;
  }
  .banner .search-sec .downsec .dropdown img {
    top: -29px;
  }

  .searchjob-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 11px;
  }
  .mobilebtn {
    display: block !important;
  }
  .banner .search-sec .downsec {
    margin-bottom: 20px;
  }
  .current-openings .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .current-openings .row .right-sec .card .cardbages {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /* flex-direction: column; */
  }
  .current-openings .row .left-sec {
    display: none;
  }
  .current-openings .row .left-secFilter {
    display: block;
  }
  .current-openings .row .right-sec .card .cardtop .typo-h3 {
    font-size: 24px;
  }
  .current-openings .row .right-sec .card .cardbages .typo-h6 {
    font-size: 12px;
    margin-top: 10px;
  }
  .current-openings .row .right-sec .card .cardinfo .typo-h6 {
    font-size: 14px;
  }
  .current-openings .row .right-sec .bottom-card .bottomcardtop .typo-h4 {
    font-size: 18px;
  }
  .current-openings .row .right-sec .bottom-card .bottomcardtop .typo-p-14 {
    font-size: 12px;
  }
  .current-openings .row .togalmobilenav {
    display: block;
  }
  .applynow-mobile {
    display: block !important;
    margin-top: 18px;
  }
  .applynow-web {
    display: none;
  }
  .jobdes-banner .container .jobhero-text .jobhero-texttop .typo-h3 {
    font-size: 24px;
  }
  .jobdes-banner .container .jobhero-text {
    width: 100%;
  }
  .jobdes-banner .container .jobhero-text .jobhero-texttop .jobhero-textbages .typo-h6 img {
    width: 16px;
    height: 16px;
  }
  .jobdes-banner .container .jobhero-text .jobhero-texttop .jobhero-textbages .typo-h6 {
    font-size: 12px;
  }
  .jobdes-banner .container .jobhero-text {
    top: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 98%;
    padding: 9rem 0;
  }
  .jobhero-btn {
    margin-top: 14px;
  }
  .button-secondary {
    color: #ffffff;
    background-color: #5f56c3;
    padding: 6px;
    /* font-size: 16px; */
  }
  .button-whitebg {
    color: #52bd94;
    background-color: #ffffff;
    padding: 10px;
    font-size: 14px;
  }
  .thankyoubox .line:before {
    left: 35%;
  }
  .thankyoubox .line:after {
    right: 35%;
  }
  .form-container .form-inner .formfield {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .form-container .form-inner .formfield .leftfield {
    width: 100%;
  }
  .form-container .form-inner .formfield .rightfield {
    width: 100%;
  }
  .jobdescription .breifdec .benefits-part .ben1 {
    width: 50%;
  }
  .footersec .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    -webkit-box-pack: left;
    justify-content: left;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .filterIcon {
    display: block !important;
  }
  .banner .search-sec .downsec .search {
    display: block;
  }

  .banner .search-sec .downsec {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .banner .search-sec .downsec .search {
    max-width: 290px;
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 542px) {
  .filterIcon {
    position: absolute;
    display: block !important;
    color: #52bd94;
    font-size: 24px;
  }

  .navbar .navbar-container .logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar .navbar-container .logo .typo-h4 {
    font-size: 16px !important;
    margin: 5px 0 !important;
  }
  .logo a {
    width: 100% !important;
  }
  .navbar .navbar-container .logo img {
    max-width: 150px;
  }
  .navbar .navbar-container .logo .typo-h4 {
    margin-left: 18px;
    font-size: 18px;
  }
  .banner {
    height: 732px;
  }
  .banner .hero-text {
    top: 15% !important;
  }
}

@media (max-width: 420px) {
  .navbar .navbar-container .logo img {
    max-width: 125px;
  }
  .navbar .navbar-container .button {
    font-size: 14px !important;
  }
  .navbar .navbar-container .logo .typo-h4 {
    margin-left: 12px;
    font-size: 14px;
  }
  .banner .hero-text {
    top: 13% !important;
    left: 0 !important;
    padding: 0 18px;
  }
  .thankyoubox .line:before {
    left: 30%;
  }
  .thankyoubox .line:after {
    right: 30%;
  }
}

@media (max-width: 390px) {
  .navbar .navbar-container .logo img {
    max-width: 100px;
  }
  .navbar .navbar-container .button {
    font-size: 12px !important;
  }
  .navbar .navbar-container .logo .typo-h4 {
    margin-left: 12px;
    font-size: 12px;
  }

  .banner .search-sec .dropdown .dd-button {
    width: 50px !important;
  }
  .button.button-secondary.mobilebtn {
    padding: 8px;
    /* font-size: 12px; */
  }
  .banner .search-sec .typo-h4 {
    margin-top: 24px;
  }
  .banner .hero-text .typo-h6 {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .banner .search-sec .typo-h4 {
    margin-bottom: 8px;
  }
  .jobdescription .breifdec .benefits-part .ben1 {
    width: 100%;
  }
  .jobdescription .breifdec .benefits-part {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .jobdes-banner .container .jobhero-text {
    padding: 7rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .jobhero-btn .button-secondary {
    margin-top: 12px;
  }
  .jobdes-banner .container .jobhero-text .jobhero-texttop .jobhero-textbages {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .jobdes-banner .container .jobhero-text .jobhero-texttop .jobhero-textbages .typo-h6 {
    margin-top: 9px;
  }
  .jobdes-banner .container .jobhero-text .jobhero-texttop .typo-h3 {
    font-size: 18px;
  }
  .applynow-mobile {
    display: block !important;
    margin-top: 18px;
  }
  .applynow-web {
    display: none;
  }
  .current-openings .row .right-sec .card .cardtop .typo-h3 {
    font-size: 24px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .current-openings .row .right-sec .card .cardtop .typo-h3 .typo-h6 {
    color: #505051;
    margin-left: 0;
    margin-top: 6px;
  }
  .pagination .btn {
    width: 45px;
    height: 45px;
  }
  .pagination .page--link:link,
  .pagination .page--link:visited {
    height: 45px;
    width: 45px;
  }
  .current-openings .row .right-sec .bottom-card .bottomcardtop {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .current-openings .row .right-sec .bottom-card .bottomcardtop .bottomcardinner {
    width: 100%;
  }
  .banner .hero-text {
    top: 17% !important;
  }
}

@media (min-width: 250px) and (max-device-width: 350px) {
  .banner .hero-text {
    top: 17% !important;
    left: 0 !important;
    padding: 0 0;
  }
}

/* Responsive Media Style */
.pagination-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}

.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-container .pagination-item.selected {
  background-color: #52bd94;
}

.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-container .pagination-item .arrow.left {
  -webkit-transform: rotate(-135deg) translate(-50%);
  transform: rotate(-135deg) translate(-50%);
}

.pagination-container .pagination-item .arrow.right {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pagination-container .pagination-item.disabled {
  pointer-events: none;
  background-color: #52bd94a6;
}

.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.success-formSubmit {
  float: right;
  color: #218f66;
  font-size: 1.2em;
}

.loader-div {
  position: fixed;
  top: 60%;
  left: 50%;
}

.right-sec a {
  text-decoration: none;
}

.logo a {
  width: 20%;
}

.logo img {
  width: 100%;
}
/* NEW CUSTOM CSS */
.wt-card-title {
  margin: 0;
  color: #52bd94;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 26px;
}
small {
  color: #c2bfbf;
  font-size: 12px;
  font-weight: 600;
}

.card {
  .button-secondary {
    color: #ffffff;
    background-color: #5f56c3;
    padding: 10px 15px;
    font-size: 13px;
  }
}
.cardbages {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .tags {
    background: #e6f6f0;
    color: #52bd94;
    padding: 7px 10px;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 0;
    align-items: center;
    display: flex;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    height: 15px;
    margin-bottom: 10px;
  }
  img {
    margin-right: 7px;
  }
}
.cardinfo {
  font-weight: 400;
  margin: 27px 1px 0;
  color: #444444;
  font-size: 13px;
  line-height: 26px;
  width: 80%;
}
.chekmark-style {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 14px;
  color: #444444;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 18px;
    border: 1px solid #52bd94;
    width: 18px;
    border-radius: 4px;
    background-color: #fff;
  }
  .checkmark:after {
    left: 6px;
    top: 0px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.readmore {
  font-weight: 500;
  color: #3d79b8;
  cursor: pointer;
}

.red {
  color: red;
}
.custheight {
  height: 75vh;
}

@media screen and (max-width: 768px) {
  .cardinfo {
    width: 100%;
  }
}

/*# sourceMappingURL=main.css.map */


.phoneInput{
  width: 95%;
}

.home-section{
  position: absolute;
  top: 40%;
  left: 40%;
  width: 100%;
  color: #0d0e0e;
  font-size: 1.2em;
  font-weight: 600; 
}